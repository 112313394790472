// extracted by mini-css-extract-plugin
export var appleSiliconWarning = "download-module--appleSiliconWarning--mpwdI";
export var asset = "download-module--asset--1rW4C";
export var assetContainer = "download-module--assetContainer--FDuRK";
export var assetList = "download-module--assetList--PCl6m";
export var assetTooltip = "download-module--assetTooltip--gp2Ur";
export var bottomLinks = "download-module--bottomLinks--qWJwK";
export var downloadDetailsTooltip = "download-module--downloadDetailsTooltip--RcuaA";
export var headerContent = "download-module--headerContent--jr4mq";
export var infoTooltipContainer = "download-module--infoTooltipContainer--2Wpwd";
export var mainDownloadButton = "download-module--mainDownloadButton--gRhe-";
export var mainDownloadButtonContainer = "download-module--mainDownloadButtonContainer--0kdV7";
export var oldVersionsSection = "download-module--oldVersionsSection--Lahwb";
export var open = "download-module--open--xlhvm";
export var osBit = "download-module--osBit--yITQ7";
export var osButton = "download-module--osButton--Ck06O";
export var osSection = "download-module--osSection--t6TMu";
export var osSectionContainer = "download-module--osSectionContainer--DB+QM";
export var osSectionList = "download-module--osSectionList--Ox3m6";
export var preReleaseSwitch = "download-module--preReleaseSwitch--jNrJc";
export var preReleaseSwitchContainer = "download-module--preReleaseSwitchContainer--UeW9V";
export var preTooltipDot = "download-module--preTooltipDot--hgAVi";
export var selected = "download-module--selected--dY2v5";
export var selectedOsSection = "download-module--selectedOsSection--DiS2t";
export var tooltip = "download-module--tooltip--ivIy4";
export var tooltipDate = "download-module--tooltipDate--0CwTE";